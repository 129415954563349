<template>
  <div
    id="layout"
  >
    <header
      class="lg:fixed lg:w-full z-20 bg-white"
    >
      <LanguageSection
        class="hidden lg:flex"
      />
      <div
        id="reserved-time"
      />
    </header>
    <div
      class="px-5 lg:pt-14 lg:h-screen lg:px-0"
    >
      <div
        class="h-full"
      >
        <slot />
      </div>
    </div>
    <ConsentTool />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { breakKeys } from '~/utils/translation-utils'
import { useNuxtApp, useHead } from '#app'
import { useUserStore } from '~/store/user-store'
import LanguageSection from '~/sections/LanguageSection.vue'

const { $i18n: i18n } = useNuxtApp()
const userStore = useUserStore()

useHead({
  title: 'Payment',
  htmlAttrs: {
    lang: i18n.locale
  }
})

onMounted((): void => {
  breakKeys()
})
</script>
